<template>
  <div v-if="(IsPurchase ? true : seeNum ? seeNum >= detail.indexVals : false)" :id="detail.indexVal"
    class="quest-content">

    <!-- 题库的标题 -->
    <div v-if="isTiKu" class="tiKuQuestionTitle">
      <span v-if="showNumVal" class="score">
        <span class="questionType">{{ queType[detail.questionType] }}</span>
        <span v-if="detail.indexVal" class="indexVal">{{ detail.indexVal }}.</span>
        <span v-if="detail.questionYear" class="questionYear">({{ detail.questionYear }}真题)</span>
      </span>
      <span v-if="detail.fatherTitle" v-html="ImgSizeLimit(detail.fatherTitle)" />
      <span v-html="ImgSizeLimitp(detail.questionTitle)" />
    </div>
    <div v-else class="questionTitle">
      <div class="dis_flex">
        <div v-if="showNumVal" class="score">
          <div class="questionType">{{ queType[detail.questionType] }}</div>
          <div class="indexVal">{{ detail.indexVal }}</div>
        </div>
        <div class="title-box">
          <div class="dis_flex_bewteen" />
          <div v-if="detail.fatherTitle" class="text-black" v-html="ImgSizeLimit(detail.fatherTitle)" />
          <div class="text-black" v-html="ImgSizeLimit(detail.questionTitle)" />
        </div>
        <div style="margin-right: 10px" @click="deletePaper(detail)">
          <img v-if="wrongTopic" style="width: 26px" src="@/assets/img/homeSeventh/delete.png" alt="">
        </div>
      </div>
    </div>
    <div class="dis_flex">
      <audio v-if="detail.questionAudio" :id="'audio' + detail.indexVal" class="audio" :src="detail.questionAudio"
        controls preload />
      <video v-if="detail.questionVideo" :id="'video' + detail.indexVal" class="video" :controls="true"
        :src="detail.questionVideo" />
      <el-image v-if="detail.questionImage" class="video" fit="contain" :src="detail.questionImage"
        :preview-src-list="detail.questionImage.split(',')" />
    </div>
    <div class="qustion">
      <!-- 单选判断 -->
      <div v-if="detail.questionType == 0 || detail.questionType == 2">
        <div v-if="isSubmited">
          <div v-for="(option, optionIndex) in detail.optionList" :key="optionIndex" class="sucIcon-box" :class="detail.questionAnswer == option.num
              ? 'succes'
              : detail.questionUserAnswer == option.num
                ? 'error'
                : ''
            ">
            <img v-if="detail.questionAnswer == option.num" class="sucIcon" src="@/assets/img/learn/succes.png" alt="">
            <img v-else-if="detail.questionUserAnswer == option.num" class="sucIcon" src="@/assets/img/learn/error.png"
              alt="">
            <div v-else class="sucIcon-div" />
            <div class="dis_flex">
              <div class="tips">{{ option.num }}.</div>
              <div class="titles" v-html="ImgSizeLimit(option.val)" />
            </div>
          </div>
        </div>
        <el-radio-group v-else v-model="detail.questionUserAnswer" :disabled="isSubmited" class="redRadio" @change="task">
          <el-radio v-for="(option, optionIndex) in detail.optionList" :key="optionIndex" size="medium "
            :label="option.num">
            <div class="dis_flex">
              <div class="tips">{{ option.num }}.</div>
              <div class="titles" v-html="ImgSizeLimit(option.val)" />
            </div>
          </el-radio>
        </el-radio-group>
      </div>

      <!-- 多选题 -->
      <div v-else-if="detail.questionType == 1 || detail.questionType == 6">
        <div v-if="isSubmited">
          <div v-for="(option, optionIndex) in detail.optionList" :key="optionIndex" class="sucIcon-box" :class="detail.questionAnswer.split(',').includes(option.num)
              ? 'succes'
              : detail.questionUserAnswer.includes(option.num)
                ? 'error'
                : ''
            ">
            <img v-if="detail.questionAnswer.split(',').indexOf(option.num) != -1" class="sucIcon"
              src="@/assets/img/learn/succes.png" alt="">
            <img v-else-if="detail.questionUserAnswer.indexOf(option.num) != -1" class="sucIcon"
              src="@/assets/img/learn/error.png" alt="">
            <div v-else class="sucIcon-div" />
            <div class="dis_flex">
              <div class="tips">{{ option.num }}.</div>
              <div class="titles" v-html="ImgSizeLimit(option.val)" />
            </div>
          </div>
        </div>
        <el-checkbox-group v-else-if="!wrongTopic" v-model="detail.questionUserAnswer" :disabled="isSubmited"
          class="block" @change="task">
          <el-checkbox v-for="(option, optionIndex) in detail.optionList" :key="optionIndex" size="medium "
            :label="option.num">
            <div class="dis_flex">
              <div class="tips">{{ option.num }}.</div>
              <div class="titles" v-html="ImgSizeLimit(option.val)" />
            </div>
          </el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-else v-model="detail.questionUserAnswer" :disabled="isSubmited" class="block">
          <el-checkbox v-for="(option, optionIndex) in detail.optionList" :key="optionIndex" size="medium "
            :label="option.num">
            <div class="dis_flex">
              <div class="tips">{{ option.num }}.</div>
              <div class="titles" v-html="ImgSizeLimit(option.val)" />
            </div>
          </el-checkbox>
        </el-checkbox-group>
        <div v-if="isRandom && !isSubmited" class="make-up-answer" @click="makeUpAnswer">
          确认答案
        </div>
        <div v-else-if="wrongTopic" class="make-up-answer" @click="task">
          确认答案
        </div>
      </div>

      <!-- 组合题 -->
      <div v-else-if="detail.questionType == 5 && !wrongTopic" class="block">

        <div v-for="(item, index) in detail.childSubject" :key="index">

          <!-- 刷题库不显示得分 -->
          <TaskCar :paper-id="paperId" :detail="item" :is-purchase="IsPurchase" :is-submited="isSubmited" :source="source"
            :is-points="isTiKu ? false : true" module-type="1" :is-ti-ku="true" @task="task" />
        </div>
        <div v-if="isRandom && !isSubmited" class="make-up-answer" @click="makeUpAnswer">
          确认答案
        </div>
      </div>
      <div v-else-if="detail.questionType == 5 && wrongTopic" class="block">

        <div v-for="(item, index) in detail.childSubject" :key="index">

          <TaskCar :paper-id="paperId" :detail="item" :is-purchase="IsPurchase" :is-submited="isSubmited" :source="source"
            :is-points="isTiKu ? false : true" module-type="1" :is-ti-ku="true" />
        </div>
        <div class="make-up-answer" @click="task">确认答案</div>
      </div>
      <!-- 填空题简答题 -->
      <div v-else-if="!isSubmited && !wrongTopic">
        <el-input v-model="detail.textAnswer" :disabled="isSubmited" type="textarea" :rows="6" placeholder="请输入答案"
          @blur="changeTask" />

        <div class="w-b-100 uploadImg">
          <el-upload ref="upload" :action="imgAction()" name="upload" :headers="Domainjwt" :limit="6"
            list-type="picture-card" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus" />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" title="预览" width="600" append-to-body class="onPreview">
            <img :src="dialogImageUrl" style="display: block; width: 100%; height: 100%">
          </el-dialog>
          <div class="tips">点击上传图片（最多可上传6张，每张小于2M）</div>
        </div>
      </div>
      <div v-else-if="!isSubmited && wrongTopic">
        <el-input v-model="detail.textAnswer" :disabled="isSubmited" type="textarea" :rows="6" placeholder="请输入答案" />

        <div class="w-b-100 uploadImg">
          <el-upload ref="upload" :action="imgAction()" name="upload" :headers="Domainjwt" :limit="6"
            list-type="picture-card" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus" />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" title="预览" width="600" append-to-body class="onPreview">
            <img :src="dialogImageUrl" style="display: block; width: 100%; height: 100%">
          </el-dialog>
          <div class="tips">点击上传图片（最多可上传6张，每张小于2M）</div>
        </div>
        <div class="make-up-answer" @click="changeTask">确认答案</div>
      </div>
    </div>
    <!-- j解析 -->
    <div v-if="isSubmited && detail.questionType != 5" class="answer margin-top solid-top">
      <div v-if="(detail.questionType != 4 && detail.questionType != 3)" class="answerShow dis_flex_between">
        <div class="dis_flex">
          <div class="dis_flex solid-bottom">
            <span class="fles-shrink">正确答案：</span>
            <div v-html="ImgSizeLimit(detail.questionAnswer)" />
          </div>
          <div v-if="isTry" class="dis_flex">
            <span v-if="isTry" class="fles-shrink">您的答案：</span>
            <span v-if="isTry" :class="Number(detail.isCorrect) == 0 ? 'danger' : 'success'">
              {{
                detail.questionType == 1 || detail.questionType == 6
                ? detail.questionUserAnswer.join(',')
                : detail.questionUserAnswer
              }}
            </span>
          </div>
        </div>

        <div v-if="isPoints && isZsd && isTry" class="dis_flex fles-shrink">
          <span>本题得分：</span>
          <span v-if="(detail.questionType == 3 || detail.questionType == 4) &&
            (!detail.isRead || detail.isRead == 0) &&
            examType == 3
            " class="danger">暂未阅卷</span>
          <span v-else class="danger">{{ detail.getPoints ? detail.getPoints : 0 }}分</span>
        </div>
      </div>
      <div v-if="(detail.questionType == 4 || detail.questionType == 3) && isTry" class="jiexi">
        <div class="flex-between-c answerTitle">
          <div v-if="isTry" class="fles-shrink">您的答案：</div>
          <div v-if="isPoints && isTry" class="dis_flex Points fles-shrink">
            <span>本题得分：</span>
            <span v-if="(detail.questionType == 3 || detail.questionType == 4) &&
              (!detail.isRead || detail.isRead == 0) &&
              examType == 3
              " class="danger">暂未阅卷</span>
            <span v-else class="danger">{{ detail.getPoints ? detail.getPoints : 0 }}分</span>
          </div>
        </div>
        <div v-if="detail.questionUserAnswer" class="jiexi-text">
          <div class="textAnswer">
            {{ JSON.parse(detail.questionUserAnswer).answerText }}
          </div>
          <div v-if="JSON.parse(detail.questionUserAnswer).urlImg" class="imgBox">
            <img v-for="(item, index) in JSON.parse(
              detail.questionUserAnswer
            ).urlImg.split(',')" :key="index" :src="item" @click="magnify(item)">
          </div>
          <el-dialog :visible.sync="dialogVisible" title="预览" width="600" append-to-body class="onPreview">
            <img :src="dialogImageUrl" style="display: block; width: 100%; height: 100%">
          </el-dialog>
        </div>
      </div>
      <div v-if="detail.questionType == 4 || detail.questionType == 3" class="jiexi">
        <div class="flex-between-c answerTitle">
          <div class="fles-shrink">正确答案：</div>
        </div>
        <div v-if="detail.questionAnswer" class="jiexi-text" v-html="ImgSizeLimit(detail.questionAnswer)" />
      </div>
      <div class="jiexi">
        <div class="answerTitle fles-shrink">参考解析：</div>
        <div v-if="detail.questionAnswerAnalysis" class="jiexi-text"
          v-html="ImgSizeLimit(detail.questionAnswerAnalysis)" />
        <div class="dis_flex">
          <audio v-if="detail.questionAnswerAnalysisAudio" :id="'audioAns' + detail.indexVal" class="audio"
            :src="detail.questionAnswerAnalysisAudio"
            poster="https://bjetxgzv.cdn.bspapp.com/VKCEYUGU-uni-app-doc/7fbf26a0-4f4a-11eb-b680-7980c8a877b8.png"
            name="音频文件" controls preload loop />
          <video v-if="detail.questionAnswerAnalysisVideo" :id="'videoAns' + detail.indexVal" class="video"
            :controls="true" :src="detail.questionAnswerAnalysisVideo" />
          <el-image v-if="detail.questionAnswerAnalysisImage" class="video" fit="contain"
            :src="detail.questionAnswerAnalysisImage" :preview-src-list="detail.questionAnswerAnalysisImage.split(',')" />
        </div>
      </div>
      <div v-if="!isTiKu" class="jiexi">
        <div class="answerTitle fles-shrink">关联试卷：</div>
        <div class="examPaper">
          <div v-for="(examitem, examindex) in detail.paperList" v-if="!detail.examIsShow && examindex < 5"
            :key="examindex">
            <img src="@/assets/img/study/sj22.png" alt="">
            <div>{{ examitem.name }}</div>
          </div>
          <div v-if="detail.paperList &&
            detail.paperList.length > 5 &&
            !detail.examIsShow
            " class="examShowMore" @click="examShowMore(detail)">
            查看更多
          </div>
          <div v-for="(examitem, examindex) in detail.paperList" v-if="detail.examIsShow" :key="examindex">
            <img src="@/assets/img/study/sj22.png" alt="">
            <div>{{ examitem.name }}</div>
          </div>
        </div>
      </div>
      <!-- 所属试卷 -->
      <!-- <div class="jiexi">
        <div class="answerTitle fles-shrink">所属试卷：</div>
      </div> -->
      <div v-if="source != 'kowledgePoints'" class="jiexi">
        <div class="answerTitle fles-shrink dis_flex">
          <div>知识点：</div>
          <div class="btn" @click="examine">查看知识点</div>
        </div>
        <div ref="recommendBox">
          <kowledgePoints v-show="showPoints" ref="kowledgePoints" type="3" />
        </div>
      </div>
    </div>
    <div v-else-if="showQue && detail.questionType != 5" class="answer margin-top solid-top">
      <div v-if="detail.questionType != 4 && detail.questionType != 3" class="answerShow dis_flex_between">
        <div class="dis_flex">
          <div class="dis_flex solid-bottom">
            <span class="fles-shrink">正确答案：</span>
            <div v-html="ImgSizeLimit(detail.questionAnswer)" />
          </div>
          <div v-if="isTry" class="dis_flex">
            <span v-if="isTry" class="fles-shrink">您的答案：</span>
            <span v-if="isTry" :class="Number(detail.isCorrect) == 0 ? 'danger' : 'success'">
              {{
                detail.questionType == 1 || detail.questionType == 6
                ? detail.questionUserAnswer.join(',')
                : detail.questionUserAnswer
              }}
            </span>
          </div>
        </div>

        <div v-if="isPoints && isTry" class="dis_flex fles-shrink">
          <span v-if="isTry">本题得分：</span>
          <span v-if="isTry &&
            (detail.questionType == 3 || detail.questionType == 4) &&
            (!detail.isRead || detail.isRead == 0) &&
            examType == 3
            " class="danger">暂未阅卷</span>
          <span v-if="isTry" class="danger">{{ detail.getPoints ? detail.getPoints : 0 }}分</span>
        </div>
      </div>
      <div v-if="(detail.questionType == 4 || detail.questionType == 3) && isTry" class="jiexi">
        <div v-if="usTry" class="flex-between-c answerTitle">
          <div v-if="isTry" class="fles-shrink">您的答案：</div>
          <div v-if="isPoints && isTry" class="dis_flex Points fles-shrink">
            <span>本题得分：</span>
            <span v-if="(detail.questionType == 3 || detail.questionType == 4) &&
              (!detail.isRead || detail.isRead == 0) &&
              examType == 3
              " class="danger">暂未阅卷</span>
            <span v-else class="danger">{{ detail.getPoints ? detail.getPoints : 0 }}分</span>
          </div>
        </div>
        <div v-if="detail.questionUserAnswer" class="jiexi-text">
          <div class="textAnswer">
            {{ JSON.parse(detail.questionUserAnswer).answerText }}
          </div>
          <div v-if="JSON.parse(detail.questionUserAnswer).urlImg" class="imgBox">
            <img v-for="(item, index) in JSON.parse(
              detail.questionUserAnswer
            ).urlImg.split(',')" :key="index" :src="item" @click="magnify(item)">
          </div>
          <el-dialog :visible.sync="dialogVisible" title="预览" width="600" append-to-body class="onPreview">
            <img :src="dialogImageUrl" style="display: block; width: 100%; height: 100%">
          </el-dialog>
        </div>
      </div>
      <div v-if="detail.questionType == 4 || detail.questionType == 3" class="jiexi">
        <div class="flex-between-c answerTitle">
          <div class="fles-shrink">正确答案：</div>
        </div>
        <div v-if="detail.questionAnswer" class="jiexi-text" v-html="ImgSizeLimit(detail.questionAnswer)" />
      </div>
      <div class="jiexi">
        <div class="answerTitle fles-shrink">参考解析：</div>
        <div v-if="detail.questionAnswerAnalysis" class="jiexi-text"
          v-html="ImgSizeLimit(detail.questionAnswerAnalysis)" />
        <div class="dis_flex">
          <audio v-if="detail.questionAnswerAnalysisAudio" :id="'audioAns' + detail.indexVal" class="audio"
            :src="detail.questionAnswerAnalysisAudio"
            poster="https://bjetxgzv.cdn.bspapp.com/VKCEYUGU-uni-app-doc/7fbf26a0-4f4a-11eb-b680-7980c8a877b8.png"
            name="音频文件" controls preload loop />
          <video v-if="detail.questionAnswerAnalysisVideo" :id="'videoAns' + detail.indexVal" class="video"
            :controls="true" :src="detail.questionAnswerAnalysisVideo" />
          <el-image v-if="detail.questionAnswerAnalysisImage" class="video" fit="contain"
            :src="detail.questionAnswerAnalysisImage" :preview-src-list="detail.questionAnswerAnalysisImage.split(',')" />
        </div>
      </div>
      <div v-if="!isTiKu" class="jiexi">
        <div class="answerTitle fles-shrink">关联试卷：</div>
        <div class="examPaper">
          <div v-for="(examitem, examindex) in detail.paperList" v-if="!detail.examIsShow && examindex < 5"
            :key="examindex">
            <img src="@/assets/img/study/sj22.png" alt="">
            <div>{{ examitem.name }}</div>
          </div>
          <div v-if="detail.paperList &&
            detail.paperList.length > 5 &&
            !detail.examIsShow
            " class="examShowMore" @click="examShowMore(detail)">
            查看更多
          </div>
          <div v-for="(examitem, examindex) in detail.paperList" v-if="detail.examIsShow" :key="examindex">
            <img src="@/assets/img/study/sj22.png" alt="">
            <div>{{ examitem.name }}</div>
          </div>
        </div>
      </div>
      <!-- 所属试卷 -->
      <!-- <div class="jiexi">
        <div class="answerTitle fles-shrink">所属试卷：</div>
      </div> -->
      <div v-if="source != 'kowledgePoints'" class="jiexi">
        <div class="answerTitle fles-shrink dis_flex">
          <div>知识点：</div>
          <div class="btn" @click="examine">查看知识点</div>
        </div>
        <div ref="recommendBox">
          <kowledgePoints v-show="showPoints" ref="kowledgePoints" type="3" />
        </div>
      </div>
    </div>
    <div v-if="isSubmited &&
      detail.questionType != 5 &&
      source != 'kowledgePoints' &&
      !isTiKu
      " class="queBut" @click="queClick(detail)">
      我要提问
    </div>
    <!-- 答疑 -->
    <!-- 课程答疑 -->
    <answer-view v-if="answerShow" :type="2" :status="4" :course-id="paperId" :is-purchase="IsPurchase"
      :chapter-id="queTi.subjectId" :if-list="false" @close="close" />
  </div>
</template>

<script>
import { queType } from '@/api/emun'
import answerView from '@/components/Know/course/answerView.vue'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import { setInfo, setToken, getInfo, getDomainToken } from '@/api/cookies'
// import kowledgePoints from '@/components/kowledgePoints.vue'
import TaskCar from './taskCar.vue'
export default {
  name: 'TaskCar1',
  components: {
    TaskCar,
    answerView,
    kowledgePoints: () => import('@/components/kowledgePoints.vue')
  },
  props: {
    // 是否是随机模式/试做模式，随机模式点击即查看解析
    isRandom: {
      type: Boolean,
      default: false
    },
    wrongTopic: {
      type: String,
      default: ''
    },
    // 是否是题库，题库不展示我要提问 关联试卷
    isTry: {
      type: Boolean,
      default: false
    },
    isSubmited: {
      // 是否是解析
      type: Boolean,
      default: false
    },
    // isTry: {
    //   // 是否是解析
    //   type: Boolean,
    //   default: false
    // },
    isPoints: {
      // 是否显示得分
      type: Boolean,
      default: true
    },
    start: {
      // 是否是暂停
      type: Boolean,
      default: false
    },
    IsPurchase: {
      // 是否购买
      type: Boolean,
      default: false
    },
    detail: {},
    // 每条数据下标
    detailId: {
      type: Number,
      default: 0
    },
    detailIndex: {
      type: Number,
      default: 0
    },
    queCarBoxIndex: {
      type: Number,
      default: 0
    },
    paperId: { type: String | Number },
    isZsd: { type: String | Number, default: true },
    seeNum: {
      type: Number,
      default: 0
    },
    source: { type: String }, // 来源页面
    examType: { type: String | Number },
    moduleType: { type: String } // type 1 组合题
  },
  data() {
    return {
      showPoints: false,
      isTiKu: false,
      queType: queType,
      answerShow: false,
      queTi: null,
      showQue: false,
      imgAnswer: [],
      dialogVisible: false,
      dialogImageUrl: '',
      subjectIds: '',
      Domainjwt: {
        Domainjwt: getDomainToken()
      },

    }
  },
  computed: {
    showNumVal() {
      if (this.isTiKu) {
        return true
      } else {
        return this.detail.questionType != 5
      }
    }
  },
  watch: {
    start() {
      if (!this.start) {
        /*   audioAns;
        videoAns; */
        var audio = document.getElementById('audio' + this.detail.indexVal) // 必须用原生js获取id，jquery无效
        if (audio) {
          audio.pause() // 这个就是暂停
        }
        var audioAns = document.getElementById(
          'audioAns' + this.detail.indexVal
        ) // 必须用原生js获取id，jquery无效
        if (audioAns) {
          audioAns.pause() // 这个就是暂停
        }
        var video = document.getElementById('video' + this.detail.indexVal) // 必须用原生js获取id，jquery无效
        if (video) {
          video.pause() // 这个就是暂停
        }
        var videoAns = document.getElementById(
          'videoAns' + this.detail.indexVal
        ) // 必须用原生js获取id，jquery无效
        if (videoAns) {
          videoAns.pause() // 这个就是暂停
        }
      }
    }
  },
  async created() {
    
   
    // this.detail.forEach((e) => {
    // this.$forceUpdate()
    // })
  },
  async mounted() {
    this.$nextTick(() => {
      this.detail.examIsShow = false
    }) 
  },
  methods: {
    magnify(val) {
      this.dialogVisible = true
      this.dialogImageUrl = val
    },
    // 上传图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    async deletePaper(val) {

      const res = await answerClient.deleteMistakeId(val.subjectMistakeId)
      if (res.code == 0) {
        this.$notify({
          title: '成功',
          message: '成功删除试题',
          type: 'success',
          duration: 2000
        })
      }
      await this.$emit('getShitiList', val)
      await this.$parent.previousTopic()
    },

    handleAvatarSuccess(res, file, fileList) {
     
      if (res.isSuccess == true) {
        this.imgAnswer.push(res.path)
        this.changeTask()
      }
    },
    handleRemove(file, fileList) {
      this.imgAnswer = this.imgAnswer.filter((item) => {
      
        return item !== file.response.path
      })
      this.changeTask()
    },
    changeTask() {
      this.detail.questionUserAnswer = {
        answerText: this.detail.textAnswer ? this.detail.textAnswer : '',
        urlImg: this.imgAnswer.join(',')
      }
      this.detail.questionUserAnswer = JSON.stringify(
        this.detail.questionUserAnswer
      )
      this.task()
    },
    task() {
      if (this.wrongTopic) {
        this.showQue = true
        this.$emit('NextTopic', this.showQue)
        this.$emit('task')
      }
      if (this.isRandom) {
        if (
          this.detail.questionType == 1 ||
          this.detail.questionType == 5 ||
          this.detail.questionType == 6
        ) { return }
        this.$emit('task', this.detailId)
      }
      this.$emit('task')
    },
    // 组合题/多选题新增确认答案
    makeUpAnswer() {
      this.$emit('task', this.detailId)
    },
    queClick(item) {
      this.queTi = item
      this.answerShow = true
    },
    close() {
      this.answerShow = false
      this.$forceUpdate()
    },
    init() {
      // this.$nextTick(() => {
      //   this.$refs.kowledgePoints.getData(this.detail.id);
      // });
    },

    examine() {
      const that = this
      this.$nextTick(() => {
        this.showPoints = !this.showPoints
        if (this.showPoints) {
        
          that.$refs.kowledgePoints.getData(this.detail.subjectId)
        }
      })
    },
    examShowMore(item) {
      item.examIsShow = true
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
/* 解析对错 */
.border-lan {
  border: 1px solid #63abea !important;
}

.border-red {
  border: 1px solid rgba(249, 40, 40, 1) !important;
}

.border-bai {
  border: 1px solid #f9f9f9 !important;
}

/* 音频视频 */
.video {
  width: 200px;
  height: 200px;
  margin: 20px 20px 0px;
  background: #fff;
}

.audio {
  margin: 20px auto 0px;
  display: table;
  flex-shrink: 0;
}

/* shitileix */
.questionType {
  height: 24px;
  background: #c4c4c4 linear-gradient(130deg, #ff8551 0%, #ff5e51 100%);
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  padding: 0px 10px;
  margin-right: 10px;
  display: inline-block;
}

.indexVal {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

/* 答题卡 */
.quest-content {
  border-radius: 8px;
  padding-bottom: 32px;
  margin-bottom: 20px;

  .questionTitle {
    border-radius: 4px;
    padding: 32px 32px 0px;
    width: 100%;

    .dis_flex {
      align-items: flex-start;
    }

    .score {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      line-height: 32px;
      color: #171920;
      margin-right: 15px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }

    .text-black {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
    }

    .title-box {
      margin-top: 3px;
      width: 100%;
      display: flex;
    }
  }

  .tiKuQuestionTitle {
    border-radius: 4px;
    padding: 32px 32px 0px;
    width: 100%;
    line-height: 32px;
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    .score {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      line-height: 32px;
      color: #171920;
      margin-right: 5px;
      line-height: 32px;
      flex-shrink: 0;

      .questionType {
        position: relative;
        top: -1px;
      }

      .questionYear {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: 5px;
      }
    }

    .text-black {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
    }

    .title-box {
      margin-top: 3px;
      width: 100%;
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }

  /deep/ img {
    display: inline-block;
  }

  .qustion {
    padding: 0px 34px 0px;

    /deep/.el-radio-group,
    /deep/.el-checkbox-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px;

      .el-radio,
      .el-checkbox {
        display: flex;
        margin-top: 40px;
        white-space: inherit;

        /* 单选 */
        .el-radio__input {
          margin-top: 6px;
          /*  .el-radio__inner {
            width: 22px;
            height: 22px;
            border-color: #7a8084;
            background: #fff;

            &::after {
              width: 12px;
              height: 12px;
              background: #898e92;
            }
          } */
        }

        /* 多选 */
        .is-checked {
          .el-checkbox__inner {
            background-color: #ff5d51 !important;
            border-color: #ff5d51 !important;

            &::after {
              border: 3px solid #fff;
              width: 5px;
              height: 10px;
              border-left: 0;
              border-top: 0;
              margin-left: 2px;
            }
          }
        }

        .el-checkbox__input {
          margin-top: 6px;

          .el-checkbox__inner {
            width: 22px;
            height: 22px;
            border-color: #7a8084;
            /*     transition: none; */
            transition: transform 0.15s ease-in 0.05s;
            background: #fff;
          }
        }

        .el-radio__label,
        .el-checkbox__label {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          line-height: 32px;
          color: #171920;

          .tips {
            margin-right: 8px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .titles {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
}

/* 答案解析 */
.answer {
  background: #fff;
  margin: 0px 32px;

  /* 我的答案 */
  .answerShow {
    width: 100%;
    background: #f7f7f7;
    padding: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666;
    line-height: 16px;

    .iconfont {
      font-size: 10px;
      margin-right: 10px;
    }

    .solid-bottom {
      color: #333333;
      margin-right: 40px;
    }
  }

  .Points {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666;
    line-height: 16px;
  }

  .jiexi {
    .answerTitle {
      margin: 40px 0px 20px;
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      border-left: 4px solid #ff5d51;
      padding-left: 12px;

      .btn {
        font-size: 14px;
        padding: 2px 10px;
        border-radius: 8px;
        background-color: #ff5d51;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .dis_flex {
      display: flex;
      align-items: center;
    }

    .jiexi-text {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 26px;

      .imgBox {
        margin-top: 20px;

        img {
          width: 100px;
          height: 100px;
          margin-right: 10px;
        }
      }
    }
  }
}

.uploadImg {
  margin-top: 20px;

  .el-dialog__body {}
}

/* 简答题 */
.cu-form-group {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;

  .title {
    padding-right: 8px;
    font-size: 23px;
    color: #f25846;
    line-height: 33px;
  }

  textarea {
    height: 40vh;
    margin-top: 0px;
  }
}

/deep/.redRadio {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 40px;

  .el-radio__inner {
    width: 24px;
    height: 24px;

    &:after {
      width: 10px;
      height: 10px;
    }
  }

  .el-radio__label {
    align-items: center;
    display: flex;
  }
}

.sucIcon-box {
  display: flex;
  padding: 22px 0px;

  .tips,
  .titles {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }
}

.sucIcon {
  width: 20px;
  height: 20px;
  opacity: 1;
  margin-right: 8px;
  flex-shrink: 0;
}

.sucIcon-div {
  width: 20px;
  height: 20px;
  opacity: 1;
  margin-right: 8px;
  border: 1px solid #9fa4ad;
  border-radius: 50%;
  flex-shrink: 0;
}

.succes {

  .tips,
  .titles {
    color: #34b751;
  }
}

.error {

  .tips,
  .titles {
    color: #ff5d51;
  }
}

.quest-seek {
  background: rgba(153, 153, 153, 0.2) !important;
}

.queBut {
  width: 104px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #ff5e51;
  margin: 10px 0px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ff5e51;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  margin-left: 34px;
}

// ::v-deep .el-upload--picture-card {
//   width: 100px !important;
//   height: 100px !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .el-upload-list__item {
//     width: 100px !important;
//     height: 100px !important;
//   }
// }
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;

  .el-icon-plus {
    margin-left: -3px;
  }
}

/deep/ .el-upload {
  width: 100px;
  height: 100px;
  line-height: 107px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/deep/ .avatar {
  width: 100px;
  height: 100px;
}

.examPaper {
  display: flex;
  flex-wrap: wrap;

  >div {
    width: 50%;
    white-space: nowrap; //不换行
    text-overflow: ellipsis; //将文本溢出显示为（…）
    overflow: hidden; //溢出隐藏
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-top: 6px;

    img {
      width: 16px;
      margin-right: 6px;
    }
  }
}

.examShowMore {
  cursor: pointer;
}

.make-up-answer {
  width: 124px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #ff5e51;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5e51;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
}
</style>